<template>
  <div class="fundHeroInfo" v-show="showMessage">
    <i class="icon icon-046-information-circle icon--s2"></i>
    <div v-html="message"></div>
  </div>
</template>

<style>
html:not([theme="light"]) .fundHeroInfo {
  background: var(--ucolor-qxpnav-menu-background);
  border-bottom: 2px solid var(--ucolor-app-background);
}
.fundHeroInfo {
  padding: 20px;
  border-bottom: 1px solid var(--color-aluminum);
  margin-bottom: 8px;
  font-size: 1rem;
  display: flex;
}
.fundHeroInfo i {
  flex: 0;
  margin-right: 16px;
  align-self: center;
}
.fundHeroInfo a {
  font-weight: 600 !important;
  text-decoration: underline;
  border-bottom: 0px !important;
}
</style>

<script>
export default {
  created() {
    var navigatorlocale =
      window.navigator.userLanguage || window.navigator.language;
    if (navigatorlocale) {
      let language = navigatorlocale.split("-");
      this.languageIso = language[0] || "en";
    }
  },
  data() {
    /* showMessageUntil = 01.03.2025 */
    return {
      languageIso: "en",
      showMessageUntil: 1743465599,
      availableMessages: {
        de: "Bitte beachten Sie die Neuerungen bei IP.Risk zum Jahreswechsel! Weitere Informationen finden Sie in der <a href='http://211995.167276.eu2.cleverreach.com/m/15847920' target='_blank'>ServiceInformation - Produktivnahme der Prozesse mit FundHero S.A.</a>",
        en: "Please note the changes to IP.Risk at the beginning of the new year! Further information can be found in the <a href='http://211995.167276.eu2.cleverreach.com/m/15847920' target='_blank'>ServiceInformation - Going live of processes with FundHero S.A.</a>",
      },
    };
  },
  computed: {
    message() {
      return this.availableMessages[this.languageIso];
    },
    showMessage() {
      if (Math.floor(Date.now() / 1000) < this.showMessageUntil) {
        return true;
      }
      return false;
    },
  },
};
</script>
