<template>
  <qsui-accordion-item
    @removeCritBox="removeCritBox"
    :class="{ regression: isRegression }"
  >
    <p slot="header">{{ huCol.DisplayName }}{{ isRegression ? ".r" : "" }}</p>
    <div slot="collapse">
      <form class="form-radio-date-filter">
        <div class="radio radio-date-filter">
          <input
            type="radio"
            name="dummy"
            id="radio_on"
            ref="radio_on"
            value="radio_on"
            v-model="pickedRadio"
          />
          <label for="radio_on" @click="onChangeRadio_on()">
            <div class="input-field">
              <p>from</p>
            </div>
          </label>
          <div
            class="input-field radio-date-time-picker-buttons"
            style="display: flex; justify-content: flex-end"
          >
            <i
              class="icon icon-052-minus wvfilter-icon"
              title="One day less"
              aria-hidden="true"
              @click="onDateMinus()"
            ></i>
            <i
              class="
                icon icon-071-retry
                wvfilter-icon
                icon
                wvfilter-green-button
              "
              title="Reset to default date"
              aria-hidden="true"
              @click="onDateReset()"
            ></i>
            <i
              class="icon icon-061-plus wvfilter-icon"
              title="One day more"
              aria-hidden="true"
              @click="onDatePlus()"
            ></i>
          </div>
          <div class="radio-date-time-picker">
            <wv-date-time-input
              class="radio-date-time-picker"
              v-model="filterRadio_on.criterias[0].value"
              @input="onChangeRadio_on()"
            />
          </div>
        </div>
        <div class="radio radio-date-filter">
          <input
            type="radio"
            name="dummy"
            id="radio_btw"
            ref="radio_btw"
            value="radio_btw"
            v-model="pickedRadio"
          />
          <label
            for="radio_btw"
            class="inlineLabel"
            @click="onChangeRadio_btw()"
          >
            <div class="input-field">
              <p>btw</p>
            </div>
          </label>
          <div
            class="input-field radio-date-time-picker-buttons"
            style="display: flex; justify-content: space-evenly"
          >
            <a
              class="
                button button__icon button--secondary
                radio-date-picker-button
                invisible
              "
              href="javascript:void(0);"
            >
              <i class="icon icon-014-chevron-left" aria-hidden="true"></i>
            </a>
          </div>
          <div class="radio-date-time-picker">
            <wv-date-time-input
              class="radio-date-time-picker"
              v-model="filterRadio_btw.criterias[0].value"
              @input="onChangeRadio_btw_from()"
            />
            <wv-date-time-input
              class="radio-date-time-picker"
              v-model="filterRadio_btw.criterias[1].value"
              @input="onChangeRadio_btw_to()"
            />
          </div>
        </div>
        <div class="radio radio-date-filter">
          <input
            type="radio"
            name="dummy"
            id="radio_last"
            ref="radio_last"
            value="radio_last"
            v-model="pickedRadio"
          />
          <label for="radio_last" @click="onChangeRadio_last()">
            <div class="input-field">
              <p>last</p>
            </div>
          </label>
          <div
            class="input-field radio-date-time-picker radio-date-picker-text"
            data-init="auto"
          >
            <input
              type="text"
              class="inputNumber"
              v-model="valueAmount_last"
              @input="onChangeRadio_last()"
              @keyup.enter="$emit('onEnterKey')"
            />
          </div>
          <treeselect
            class="
              select-operator
              radio-date-time-picker radio-date-picker-text
            "
            style="text-align: center"
            v-model="valuePeriodType_last"
            :options="dateOperators"
            @input="onChangeRadio_last()"
            :clearable="false"
            :always-open="false"
            :multiple="false"
          />
        </div>
      </form>
    </div>
  </qsui-accordion-item>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import Treeselect from "@riophae/vue-treeselect";
import WvDateTimeInput from "./../date_inputs/WvDateTimeInput";

export default {
  data() {
    return {
      filterRadio_on: {
        name: "",
        binaryOperator: "and",
        criterias: [
          {
            operator: ">=",
            value: this.getNow(),
          },
        ],
      },
      filterRadio_btw: {
        name: "",
        binaryOperator: "and",
        criterias: [
          {
            operator: ">=",
            value: this.getNow(),
          },
          {
            operator: "<=",
            value: this.getNow(),
          },
        ],
      },
      filterRadio_last: {
        name: "",
        binaryOperator: "and",
        criterias: [
          {
            operator: ">=",
            value: this.getNow(),
          },
        ],
      },
      valueAmount_last: "10",
      valuePeriodType_last: "days",
      pickedRadio: "",
    };
  },
  computed: {
    dateRadio_last() {
      var today = this.getNow();
      var newdate = this.getNow();
      if (this.valueAmount_last && this.valuePeriodType_last) {
        switch (this.valuePeriodType_last) {
          case "hours":
            newdate.setHours(newdate.getHours() - this.valueAmount_last);
            break;
          case "days":
            newdate.setDate(today.getDate() - this.valueAmount_last);
            break;
          case "weeks":
            newdate.setDate(today.getDate() - this.valueAmount_last * 7);
            break;
          case "months":
            newdate.setMonth(today.getMonth() - this.valueAmount_last);
            break;
          case "years":
            newdate.setYear(today.getFullYear() - this.valueAmount_last);
            break;
        }
      }
      return newdate;
    },
  },
  methods: {
    getNow() {
      var date = new Date();
      date.setSeconds(0);
      date.setMilliseconds(0);
      return date;
    },
    onChangeRadio_on() {
      this.$emit("input", this.filterRadio_on);
      this.$refs.radio_on.click();
    },
    onChangeRadio_btw_from() {
      if (
        this.filterRadio_btw.criterias[0].value >
        this.filterRadio_btw.criterias[1].value
      )
        this.filterRadio_btw.criterias[1].value =
          this.filterRadio_btw.criterias[0].value;
      this.onChangeRadio_btw();
    },
    onChangeRadio_btw_to() {
      if (
        this.filterRadio_btw.criterias[0].value >
        this.filterRadio_btw.criterias[1].value
      )
        this.filterRadio_btw.criterias[0].value =
          this.filterRadio_btw.criterias[1].value;
      this.onChangeRadio_btw();
    },
    onChangeRadio_btw() {
      this.$emit("input", this.filterRadio_btw);
      this.$refs.radio_btw.click();
    },
    onChangeRadio_last() {
      this.filterRadio_last.criterias[0].value = this.dateRadio_last;
      this.$emit("input", this.filterRadio_last);
      this.$refs.radio_last.click();
    },
    onDateReset() {
      this.filterRadio_on.criterias[0].value = this.getNow();
      this.onChangeRadio_on();
    },
    onDateMinus() {
      // you have to set the this.myDate again, so vue can detect it changed
      this.filterRadio_on.criterias[0].value = new Date(
        this.filterRadio_on.criterias[0].value.setDate(
          this.filterRadio_on.criterias[0].value.getDate() - 1
        )
      );
      this.onChangeRadio_on();
    },
    onDatePlus() {
      // you have to set the this.myDate again, so vue can detect it changed
      this.filterRadio_on.criterias[0].value = new Date(
        this.filterRadio_on.criterias[0].value.setDate(
          this.filterRadio_on.criterias[0].value.getDate() + 1
        )
      );
      this.onChangeRadio_on();
    },
    removeCritBox() {
      // this.resetCrit()
      this.$emit("removeCritBox", this.huCol);
    },
    getWorkingday(days) {
      let businessDays = Math.abs(days);
      let counter = Math.sign(days); // set to 1 to count from next business day
      let increment = Math.sign(days); // set to 1 to count from next business day
      while (businessDays > 0) {
        var tmp = new Date();
        tmp.setHours(0, 0, 0, 0);
        // console.log("before: " + tmp);
        tmp.setDate(tmp.getDate() + counter);
        counter += increment;
        switch (tmp.getDay()) {
          case 0:
          case 6:
            break; // sunday & saturday
          default:
            businessDays--;
        }
      }
      // console.log("after: " + tmp);
      return tmp;
    },
    getNormalDay(days) {
      let normalDays = Math.abs(days);
      let counter = Math.sign(days); // set to 1 to count from next day
      let increment = Math.sign(days); // set to 1 to count from next day
      while (normalDays > 0) {
        var tmp = new Date();
        tmp.setHours(0, 0, 0, 0);
        // console.log("before: " + tmp);
        tmp.setDate(tmp.getDate() + counter);
        counter += increment;
        normalDays--;
      }
      // console.log("after: " + tmp);
      return tmp;
    },
  },
  created() {
    this.filterRadio_on.name = this.huCol.ColName;
    this.filterRadio_on.criterias[0].value = this.initialDate;

    this.filterRadio_btw.name = this.huCol.ColName;
    this.filterRadio_btw.criterias[0].value = this.dateRadio_last;
    this.filterRadio_btw.criterias[1].value = this.initialDate;

    this.filterRadio_last.name = this.huCol.ColName;
    this.filterRadio_last.criterias[0].value = this.dateRadio_last;
  },
  mounted() {
    //Default Value if specified in colForSearch
    if (this.huCol.SearchDefault) {
      if (this.huCol.SearchDefault.toLowerCase() == "nodefault") {
      } else if (this.huCol.SearchDefault.toLowerCase() == "previousfriday") {
        var previousFriday = moment().day(-2);
        this.filterRadio_on.criterias[0].value = previousFriday.toDate();
        this.onChangeRadio_on();
      } else if (this.huCol.SearchDefault.toLowerCase().startsWith("last")) {
        let svalue = this.huCol.SearchDefault.toLowerCase().replace("last", "");
        this.dateOperators.forEach((dateOperator) => {
          let periodType = dateOperator.id.toLowerCase();
          if (svalue.includes(periodType)) {
            this.valuePeriodType_last = periodType;
            this.valueAmount_last = svalue.replace(periodType, "");
          }
        });
        this.onChangeRadio_last();
      } else if (this.huCol.SearchDefault.toLowerCase().startsWith("on")) {
        let svalue = this.huCol.SearchDefault.toLowerCase().replace("on", "");
        if (svalue.includes("b")) {
          var nbDays = svalue.replace("b", "");
          var onTarget = this.getWorkingday(nbDays);
          this.filterRadio_on.criterias[0].value = onTarget;
          this.onChangeRadio_on();
        } else if (svalue.includes("d")) {
          var nbDays = svalue.replace("d", "");
          var onTarget = this.getNormalDay(nbDays);
          this.filterRadio_on.criterias[0].value = onTarget;
          this.onChangeRadio_on();
        }
      }
    } else {
      this.onChangeRadio_last();
    }
  },
  props: {
    huCol: {
      type: Object,
      default: function () {
        return {
          ColName: "DefaultValue",
          ColType: "name",
        };
      },
    },
    defOperator: {
      type: String,
      default: function () {
        return "=";
      },
    },
    dateOperators: {
      type: Array,
      default: function () {
        return [
          {
            id: "hours",
            label: "Hours",
            // children: null
          },
          {
            id: "days",
            label: "Days",
            // children: null
          },
          {
            id: "weeks",
            label: "Weeks",
            // children: null
          },
          {
            id: "months",
            label: "Months",
            // children: null
          },
          {
            id: "years",
            label: "Years",
            // children: null
          },
        ];
      },
    },
    initialDate: {
      type: Date,
      default: function () {
        return new Date();
      },
    },
    isRegression: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  components: {
    QsuiAccordionItem,
    Treeselect,
    WvDateTimeInput,
  },
};
</script>
